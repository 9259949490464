import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    // name: 'Build',
    component: () => import('../views/PageBuild.vue'),
    children: [{
        path: '',
      name: 'main',
      meta: {
          title: '团子翻译器-主页'
      },
        component: () => import('../views/content/ContentMain.vue'),
      },
      {
        path: 'changelog',
        name: 'changelog',
        meta: {
          title: '团子翻译器-更新日志'
      },
        component: () => import('../views/content/ContentChangelog.vue'),
      },
    ]
  },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: () => import('../views/PageHome.vue'),
  // },
  // 404页面
  // {
  //   path: '*', // * 表示上面路径匹配不到的都显示这个页面
  //   name: 'NotFoundComponent',
  //   component: NotFoundComponent
  // }

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
